<template>
  <!-- 忘记密码 -->
  <div class="changePassword">
    <van-nav-bar title="忘记密码"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="getPhoneCode">
      <!-- <van-field
        v-model="username"
        name="username"
        label="用户名"
        placeholder="请输入"
        input-align="right"
        required
      />-->
      <div v-show="!codeTrue">
        <van-field v-model="phone"
                   name="phone"
                   label="手机号码"
                   placeholder="请输入"
                   input-align="right"
                   required />
        <div v-if="need_slider_code "
             class="verify_box">
          <div class="text_verification"
               v-if="vertify_mode == 1">
            <input v-model="enterCode"
                   class="up1"
                   type="text"
                   :placeholder="'不区分大小写'" />
            <div class="show_identify">
              <Identify :identifyCode="identifyCode"></Identify>
            </div>
            <div class="changeCode"
                 @click="refreshCode">看不清楚，换一张</div>
          </div>
          <div class="dragVerify"
               v-else>
            <drag-verify ref="dragVerify"
                         class="drag"
                         :width="drag_width"
                         :height="drag_height"
                         :isPassing.sync="isPassing2"
                         text="请按住滑块拖动"
                         successText="验证通过"
                         handlerIcon="fa fa-angle-double-right"
                         successIcon="fa fa-check"
                         @passcallback="pass">
              <i v-show="!isPassing2"
                 slot="textBefore"
                 class="fa fa-lock"></i>
            </drag-verify>
          </div>
        </div>
        <van-field v-model="code"
                   center
                   required
                   clearable
                   label="验证码"
                   placeholder="请输入验证码"
                   :border="false"
                   use-button-slot>
          <van-button slot="button"
                      size="small"
                      type="info"
                      :disabled="!istel"
                      @click="b4gainCode">{{time}}</van-button>
        </van-field>
        <div style="margin: 16px;">
          <van-button plain
                      slot="button"
                      size="small"
                      type="info"
                      round
                      block
                      :disabled="!bfcodeTrue"
                      @click="codeTrue=true">下一步</van-button>
        </div>
      </div>
      <!-- :rules="[{ required: true, message: '请填写原密码' }]" -->
      <van-form v-show="codeTrue"
                @submit="onSubmit">
        <van-field v-model="userinfo.empcode"
                   clickable
                   type="text"
                   name="empcode"
                   label="工号"
                   placeholder="工号"
                   :readonly="true" />
        <van-field v-model="userinfo.empname"
                   clickable
                   type="empname"
                   name="empname"
                   label="姓名"
                   placeholder="姓名"
                   :readonly="true" />
        <van-field v-model="newPassword1"
                   required
                   clickable
                   type="password"
                   name="newPassword1"
                   label="新密码"
                   placeholder="新密码" />
        <van-field v-model="newPassword2"
                   required
                   clickable
                   type="password"
                   name="newPassword2"
                   label="确认密码"
                   placeholder="确认密码" />
        <!-- <p class="pswDesc">
          密码必须是8-16位的英文字母、数字、字符组合（不能 是纯数字）
        </p>-->
        <div style="margin: 16px;">
          <van-button slot="button"
                      plain
                      size="small"
                      type="info"
                      round
                      block
                      @click="codeTrue=false">上一步</van-button>
        </div>
        <div style="margin: 16px;">
          <van-button round
                      block
                      type="info"
                      :disabled="!canSave"
                      native-type="submit">提交</van-button>
        </div>
        <div class="time2"
             v-show="time2"
             @click="onClickLeft()">{{time2}}</div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast, Notify, NumberKeyboard } from 'vant'
import { getSmsCode, changePwdByPhone, getPhoneEmpInfo } from '@api/login.js'
import { encrypt, decrypt } from '@/utils/aes.js'
import { getPwdPolicy } from '@api/wx'
import Identify from '@components/imageVerification/identify'
import dragVerify from '@components/dragVerify'
export default {
  components: {
    Identify,
    dragVerify
  },
  data () {
    return {
      username: '',
      phone: '',
      key: '',
      code: '',
      s: 60,
      s2: 10,
      isClick: true,
      newPassword1: '',
      newPassword2: '',
      showBoard1: false,
      showBoard2: false,
      showBoard3: false,
      time: '获取验证码',
      time2: '',
      codeTrue: false,
      userinfo: {},
      need_slider_code: false, // 每次登录需要验证码
      vertify_mode: 0, // 验证方式 0滑块验证，1文字验证
      identifyCode: '',
      identifyCodes: '123456789ABCDEFGHGKMNPQRSTUVWXYZabcdefghgkmnpqrstuvwxyz', // 验证码规则
      enterCode: '', // 输入的验证码
      drag_width: 300,
      drag_height: 45,
      isPassing2: false
    }
  },
  computed: {
    canSave () {
      if (
        this.phone &&
        this.newPassword1 &&
        this.newPassword2 &&
        this.newPassword1 == this.newPassword2
      )
      {
        return true
      } else
      {
        return false
      }
    },
    bfcodeTrue () {
      if (this.code !== '' && this.code === this.key)
      {
        return true
      } else
      {
        return false
      }
    },
    istel () {
      let vcode = true
      if (this.need_slider_code)
      {
        if (this.vertify_mode == 1)
        {
          vcode = this.enterCode.toLowerCase() == this.identifyCode.toLowerCase()
        } else
        {
          vcode = this.isPassing2
        }
      }
      if (/^1[3456789]\d{9}$/.test(this.phone) && vcode)
      {
        return true
      } else
      {
        return false
      }
    }
  },
  created () {
    this.drag_width = window.screen.width - 30
    // 获取密码策略信息
    getPwdPolicy().then(res => {
      const data = res.data[0]
      this.need_slider_code = data.need_slider_code
      this.vertify_mode = data.vertify_mode
      this.refreshCode()
    })
  },
  methods: {
    pass () { },
    // 切换验证码
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      // console.log(this.identifyCode)
    },
    // 生成随机验证码
    makeCode (o, l) {
      for (let i = 0; i < l; i++)
      {
        this.identifyCode += this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
        ]
      }
    },
    onClickLeft () {
      this.$router.push('/login')
    },
    onClickRight () { },
    // 提交
    onSubmit () {
      const data = {
        // username: this.username,
        mobilephone: this.phone,
        newpwd1: this.newPassword1,
        newpwd2: this.newPassword2
      }
      changePwdByPhone(data).then(res => {
        if (res.iserror === '0')
        {
          Toast.success('修改成功!')
        } else
        {
          Toast.fail(res.errormsg)
        }
      })
    },
    b4gainCode () {
      // 根据手机号码获取员工相关信息
      getPhoneEmpInfo({ mobilephone: this.phone }).then(res => {
        if (res.iserror == 1)
        {
          Notify({
            type: 'danger',
            message: '获取验证码失败！原因：' + res.errormsg
          })
        } else if (res.data.length == 0)
        {
          Notify({
            type: 'danger',
            message: '不是有效的员工手机，请重新输入'
          })
        } else
        {
          this.userinfo = res.data[0]
          this.gainCode()
        }
      })
    },
    async gainCode () {
      if (this.isClick)
      {
        const res = await getSmsCode({ mobilephone: this.phone }) // 获取验证码接口
        // console.log(resA);
        // const res = resA[0] //接口传入的是数组-_||
        if (res.iserror == 0)
        {
          Notify({ type: 'success', message: '发送验证码成功' })
          let token = sessionStorage.getItem('token')
          let code = decrypt(token, res.topvalue);

          this.key = code
          if (code == 1234)
          {
            this.code = 1234
          }
        } else if (res.iserror == 1)
        {
          Notify({
            type: 'danger',
            message: '获取验证码失败！原因：' + res.errormsg
          })
        }
        this.isClick = false
        this.s = 60
        this.time = this.s + 's'
        let interval = setInterval(() => {
          this.s--
          this.time = this.s + 's'
          if (this.s < 0)
          {
            this.time = '重新获取'
            this.isClick = true
            clearInterval(interval)
          }
        }, 1000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.changePassword {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .getPhoneCode {
    .verify_box {
      background-color: #fff;
      .text_verification {
        background-color: #fff;
        padding: 20px 30px;
        display: flex;
        height: 132px;
        border-bottom: 1px #ccc solid;
        .up1 {
          outline: none;
          border: 0;
          background-color: transparent;
          color: #333;
          font-size: 28px;
          width: 40%;
          padding: 0;
        }
        .show_identify {
        }
        .changeCode {
          height: 100%;
          line-height: 92px;
          cursor: pointer;
          background: rgba(255, 255, 255, 0.7);
          color: #000;
          font-size: 24px;
          padding: 0 6px;
        }
      }
      .dragVerify {
        height: 1.2rem;
        // margin-top: 30px;
        display: flex;
        justify-content: center;
        .drag {
          --width: 134px !important;
          --pwidth: -134px !important;
          // background: red!important;
          /deep/.dv_text {
            width: 100%;
            color: red;
          }
        }
        .drag_verify {
          /deep/.dv_handler {
            background: #0b82f8 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            .fa {
              margin-top: unset;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .van-form {
    .pswDesc {
      padding: 20px 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
    .forgetPwd {
      text-align: right;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #1989fa;
      span {
        padding: 20px;
      }
    }
    .time2 {
      color: red;
      text-align: center;
      font-size: 24px;
    }
  }
}
</style>